.dynamicmedia.dynamicmedia--stage {
  position: relative;

  .cq-dd-image {
    height: 100%;

    .s7responsiveViewer {
      display: block;
      width: 100% !important;
      height: 100% !important;
      top: 0 !important;
      object-fit: cover;

      .vjs_video_3-dimensions, .s7videoelement {
        width: 100% !important;
        height: 100% !important;
        top: 0 !important;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(344.2deg, rgba(0, 0, 0, 0.49) 28.19%, rgba(0, 0, 0, 0) 71.14%);
      }
    }
  }

  .dynamicmedia__content {
    position: absolute;
    bottom: rem(154);
    left: 6%;
    z-index: 1;
    width: 88%;
    color: $stage-teaser-color;

    @include between($breakpoint-small, $breakpoint-medium) {
      bottom: rem(110);
    }

    @include until($breakpoint-small) {
      bottom: rem(60);
      left: 5%;
      width: 90%;
    }
  }

  .dynamicmedia__pretitle {
    font-size: $font-size-3;
    line-height: $font-height-3;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    margin: 0 0 rem(11);

    @include until($breakpoint-small) {
      margin-bottom: rem(4);
      font-size: $font-size-4;
      line-height: $font-height-4;
      letter-spacing: 1px;
    }
  }

  .dynamicmedia__title {
    font-size: $font-title-size-3;
    line-height: $font-title-height-3;
    font-weight: $font-weight-regular;
    width: 40.41%;

    @include until($breakpoint-small) {
      font-size: $font-title-size-3-mobile;
      line-height: $font-title-height-3-mobile;
      width: 100%;
    }
  }

  .dynamicmedia__description {
    font-size: $font-size-1;
    line-height: $font-height-1;
    margin: rem(32) 0 rem(22);
    width: 31.88%;

    p {
      font-size: $font-size-1;
      line-height: $font-height-1;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include until($breakpoint-small) {
        font-size: $font-size-1;
        line-height: $font-height-1;
      }
    }

    @include until($breakpoint-small) {
      font-size: $font-size-1;
      line-height: $font-height-1;
      width: 100%;
      margin: rem(20) 0 rem(28);
    }
  }

  .dynamicmedia__action-container {
    display: flex;
    flex-wrap: wrap;
  }

  .dynamicmedia__action-link {
    @extend .cmp-button;
    @include add_button_style("secondary-inverted");
    margin: rem(10) 0 0 0 !important;

    &:not(:last-child) {
      margin-right: 1rem !important;

      @include until($breakpoint-small) {
        margin-right: 0.5rem !important;
      }
    }

    .cmp-link__screen-reader-only {
      position: absolute;
    }
  }
}
