.carousel--gallery {

  .cmp-carousel {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    background-color: $image-gallery-background-color;

    .cmp-carousel__content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 76.32%;

      &::after {
        content: '';
        width: 100%;
        padding-bottom: 11.31%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(26, 28, 41, 0.91) 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
      }

      &::before {
        content: '';
        width: calc(100% - #{rem(56)});
        height: 1px;
        background-color: $image-gallery-border-color;
        position: absolute;
        left: rem(28);
        bottom: 0;
        z-index: 11;
        opacity: 0.3;

        @include until($breakpoint-small) {
          width: 90%;
          left: 5%;
        }
      }

      @include until($breakpoint-small) {
        height: 64%;
      }
    }

    .cmp-carousel__info {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 23.68%;
      padding-bottom: rem(56);

      @include until($breakpoint-small) {
        height: 36%;
        justify-content: flex-start;
      }
    }

    .cmp-carousel__info-caption {
      font-size: $font-size-1;
      line-height: $font-flagtitle-height;
      font-weight: $font-weight-bold;
      color: $image-gallery-text-color;
      padding-left: 13.47%;
      padding-right: 13.47%;
      position: absolute;
      left: 0;
      top: rem(-42);
      width: 100%;
      z-index: 2;
      transition: opacity 0.3s ease-in-out;

      &.is-opacity {
        opacity: 0;
      }

      @include until($breakpoint-small) {
        font-size: $font-size-1;
        line-height: $font-height-1;
        padding-left: 5%;
        padding-right: 5%;
        position: relative;
        top: 0;
        margin-top: rem(27);
        margin-bottom: rem(25);
      }
    }

    .cmp-carousel__info-description {
      font-size: $font-size-3;
      line-height: $font-height-3;
      color: $image-gallery-text-color;
      font-weight: $font-weight-regular;
      padding-left: 13.47%;
      width: 30.8%;
      min-height: rem(60);
      position: relative;

      @include until($breakpoint-small) {
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
      }
    }

    .cmp-carousel__info-index {
      position: absolute;
      top: rem(2);
      left: 19%;
      font-size: $font-date;
      line-height: $font-date-height;
      color: $image-gallery-text-color;
      font-weight: $font-weight-semi-bold;

      @include until($breakpoint-small) {
        display: none;
      }
    }
  }

  .cmp-carousel__item {

    .image {
      height: 100%;
    }

    .cmp-image {
      margin-top: 0;
      position: relative;
      height: 100%;

      .cmp-image__copyright {
        display: none;
      }
    }

    .cmp-image__container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .cmp-carousel--author-mode {

    &.cmp-carousel {
      min-height: rem(700);
    }

    .cmp-image, .image, .swiper-slide {
      min-height: rem(499);
    }

    .cmp-image {
      padding-bottom: 0;
    }
  }

  .cmp-carousel__prev, .cmp-carousel__next {
    position: absolute;
    bottom: rem(75);
    width: rem(33);
    height: rem(33);
    z-index: 1;
    transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    transition-property: opacity, background-color;
    cursor: pointer;
    text-align: left;
    overflow: hidden;
    text-indent: -9999px;
    white-space: nowrap;
    border: 1px solid $image-gallery-carousel-button-color;
    border-radius: 50%;
    background-color: transparent;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      mask-size: 100%;
      mask-repeat: no-repeat;
      mask-position: center;
      background-color: $image-gallery-carousel-button-color;
      transition: background-color 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    }

    &.swiper-button-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover, &:focus {
      background-color: $image-gallery-carousel-button-color;

      &::before {
        background-color: $image-gallery-carousel-button-active-color;
      }
    }

    @include until($breakpoint-small) {
      bottom: rem(35);
    }
  }

  .cmp-carousel__prev {
    left: calc(30% + #{rem(70)});

    &::before {
      @extend .icon-arrow-right-cool;
      transform: rotate(180deg);
    }

    @include until($breakpoint-small) {
      left: auto;
      right: calc(5% + #{rem(45)});
    }
  }

  .cmp-carousel__next {
    left: calc(30% + #{rem(115)});

    &::before {
      @extend .icon-arrow-right-cool;
    }

    @include until($breakpoint-small) {
      left: auto;
      right: 5%;
    }
  }

  .cmp-carousel__pagination {
    position: absolute;
    width: rem(53);
    height: rem(62);
    right: 5.6%;
    bottom: 28.68%;
    display: flex;
    z-index: 2;
    border-bottom: 2px solid $image-gallery-carousel-pagination-line-color;
    pointer-events: none;

    @include until($breakpoint-small) {
      bottom: 36%;
      right: 5%;
      width: rem(36);
      height: rem(26);
    }
  }

  .cmp-carousel__pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.6s cubic-bezier(0.33, 1, 0.68, 1);
    transition-property: opacity, top;
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    cursor:pointer;
    left: 0;
    top: 100%;

    &.swiper-pagination-bullet-active {
      opacity: 1;
      top: 0;

      & + .cmp-carousel__pagination-item {
        top: 0;
      }
    }

    &:has(+ .swiper-pagination-bullet-active) {
      opacity: 0.5;
    }
  }

  .cmp-carousel__pagination-item-actual {
    color: $image-gallery-carousel-pagination-color;
    font-size: $font-date;
    line-height: rem(26);
  }
}
