.teaser:not(.teaser--link-list):not(.teaser--image):not(.teaser--testimonial):not(.teaser--stage):not(.teaser--topic):not(.teaser--cta):not(.teaser--facts-figures) {
  container: basic-teaser / inline-size;
  position: relative;
  overflow: hidden;

  .cmp-teaser {
    position: relative;
    padding-bottom: 210%;
    background-color: $basic-teaser-gray-background-color;

    @container basic-teaser (inline-size > 330px) {
      padding-bottom: 195%;
    }

    @container basic-teaser (inline-size > 395px) {
      padding-bottom: 175%;
    }

    @container basic-teaser (inline-size > 409px) {
      padding-bottom: 157%;
    }

    @container basic-teaser (inline-size > 550px) {
      padding-bottom: 150%;
    }

    @container basic-teaser (inline-size > 650px) {
      padding-bottom: 115%;
    }

    @container basic-teaser (inline-size > 900px) {
      padding-bottom: 100%;
    }

    @container basic-teaser (inline-size > 1150px) {
      padding-bottom: 85%;
    }

    @container basic-teaser (inline-size > 1300px) {
      padding-bottom: 70%;
    }
  }

  .cmp-teaser__image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50.18%;
    overflow: hidden;

    & .cmp-image {
      margin: 0;
      height: 100%;
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .cmp-image__copyright {
      left: auto;
      right: 0;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 35%;
      background: linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 70%);
    }
  }

  .cmp-teaser__content {
    position: absolute;
    left: 0;
    top: 0;
    height: 49.82%;
    width: 100%;
    z-index: 2;
    padding: rem(30) 6% 0;

    p {
      font-size: $font-size-2;
      line-height: $font-height-2;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @container basic-teaser (inline-size > 264px) {
      padding-top: rem(40);
    }

    @container basic-teaser (inline-size > 399px) {
      padding-top: rem(50);
      padding-left: rem(28);
      padding-right: rem(28);
    }
  }

  .cmp-teaser__pretitle {
    display: none;
  }

  .cmp-teaser__title {
    font-weight: $font-weight-regular;
    color: $basic-teaser-gray-text-color;
    overflow: hidden;
    width: 65%;
    max-height: rem(64);
    font-size: $font-title-size-6-mobile;
    line-height: $font-title-height-6-mobile;
    margin-bottom: rem(12);
    min-height: 2 * $font-title-height-6-mobile * $font-title-size-6-mobile;
    hyphens: auto;
    overflow-wrap: break-word;

    @container basic-teaser (inline-size > 459px) {
      margin-bottom: rem(24);
      width: 46%;
      font-size: $font-title-size-7;
      line-height: $font-title-height-7;
      min-height: 2 * $font-title-height-7 * $font-title-size-7;
    }

    @container basic-teaser (inline-size > 299px) {
      width: 52%;
      max-height: rem(96);
    }
  }

  .cmp-teaser__title-link {
    color: $basic-teaser-gray-text-color;
    text-decoration: none;
    font-weight: $font-weight-regular;

    &:hover {
      text-decoration: underline;
    }
  }

  .cmp-teaser__description {
    color: $basic-teaser-gray-text-color;
    font-size: $font-size-3;
    line-height: $font-height-3;
    overflow: hidden;
    width: 78%;
    max-height: 7 * $font-size-3 * $font-height-3;

    a {
      color: $basic-teaser-gray-text-color !important;
    }

    p {
      margin-top: $font-size-2 * $font-height-2;
      margin-bottom: $font-size-2 * $font-height-2;

      @container basic-teaser (inline-size > 339px) {
        margin-top: $font-size-3 * $font-height-3;
        margin-bottom: $font-size-3 * $font-height-3;
      }
    }

    @container basic-teaser (inline-size > 339px) {
      font-size: $font-size-2;
      line-height: $font-height-2;
    }

    @container basic-teaser (inline-size > 399px) {
      width: 66%;
      max-height: 6 * $font-size-2 * $font-height-2;
    }
  }

  .cmp-teaser__action-container {
    position: absolute;
    z-index: 2;
    bottom: calc(-101% + #{rem(21)});
    left: 6%;

    @container basic-teaser (inline-size > 399px) {
      left: rem(28);
    }
  }

  .cmp-teaser__action-link {
    position: relative;
    display: flex;
    color: $basic-teaser-gray-link-color;
    text-decoration: none;
    align-items: center;
    font-size: $font-size-1-mobile;
    line-height: $font-height-1-mobile;
    padding-left: rem(38);
    min-height: rem(24);

    &::before, &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: rem(24);
      height: rem(24);

      @container basic-teaser (inline-size > 399px) {
        width: rem(33);
        height: rem(33);
      }
    }

    &::before {
      @extend .icon-arrow-diagonal;
      mask-size: 100%;
      mask-repeat: no-repeat;
      mask-position: center;
      background-color: $basic-teaser-gray-icon-color;
    }

    &::after {
      border: 1px solid $basic-teaser-gray-icon-color;
      border-radius: 50%;
    }

    &:hover {
      text-decoration: underline;
    }

    &:not(:first-child) {
      display: none;
    }

    @container basic-teaser (inline-size > 399px) {
      font-size: $font-size-1;
      line-height: $font-height-1;
      padding-left: rem(44);
      min-height: rem(33);
    }
  }

  &.teaser--style-primary {

    .cmp-teaser {
      background-color: $basic-teaser-primary-background-color;
    }

    .cmp-teaser__title {
      color: $basic-teaser-primary-text-color;
    }

    .cmp-teaser__title-link {
      color: $basic-teaser-primary-text-color;
    }

    .cmp-teaser__description {
      color: $basic-teaser-primary-text-color;

      a {
        color: $basic-teaser-primary-text-color !important;
      }
    }

    .cmp-teaser__action-link {
      color: $basic-teaser-gray-link-color;

      &::before {
        background-color: $basic-teaser-primary-icon-color;
      }

      &::after {
        border-color: $basic-teaser-primary-icon-color;
      }
    }
  }

  &.teaser--style-secondary {

    .cmp-teaser {
      background-color: $basic-teaser-secondary-background-color;
    }

    .cmp-teaser__title {
      color: $basic-teaser-secondary-text-color;
    }

    .cmp-teaser__title-link {
      color: $basic-teaser-secondary-text-color;
    }

    .cmp-teaser__description {
      color: $basic-teaser-secondary-text-color;

      a {
        color: $basic-teaser-secondary-text-color !important;
      }
    }

    .cmp-teaser__action-link {
      color: $basic-teaser-gray-link-color;

      &::before {
        background-color: $basic-teaser-secondary-icon-color;
      }

      &::after {
        border-color: $basic-teaser-secondary-icon-color;
      }
    }
  }

  &.teaser--style-tertiary {

    .cmp-teaser {
      background-color: $basic-teaser-tertiary-background-color;
    }

    .cmp-teaser__title {
      color: $basic-teaser-tertiary-text-color;
    }

    .cmp-teaser__title-link {
      color: $basic-teaser-tertiary-text-color;
    }

    .cmp-teaser__description {
      color: $basic-teaser-tertiary-text-color;

      a {
        color: $basic-teaser-tertiary-text-color !important;
      }
    }

    .cmp-teaser__action-link {
      color: $basic-teaser-gray-link-color;

      &::before {
        background-color: $basic-teaser-tertiary-icon-color;
      }

      &::after {
        border-color: $basic-teaser-tertiary-icon-color;
      }
    }
  }

  &.teaser--style-quaternary {

    .cmp-teaser {
      background-color: $basic-teaser-quaternary-background-color;
    }

    .cmp-teaser__title {
      color: $basic-teaser-quaternary-text-color;
    }

    .cmp-teaser__title-link {
      color: $basic-teaser-quaternary-text-color;
    }

    .cmp-teaser__description {
      color: $basic-teaser-quaternary-text-color;

      a {
        color: $basic-teaser-quaternary-text-color !important;
      }
    }

    .cmp-teaser__action-link {
      color: $basic-teaser-gray-link-color;

      &::before {
        background-color: $basic-teaser-quaternary-icon-color;
      }

      &::after {
        border-color: $basic-teaser-quaternary-icon-color;
      }
    }
  }
}
