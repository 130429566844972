.cmp-accordion {
  margin: rem(60) 0;
  padding: 0 $content-offset-mobile;
  position: relative;

  @include from($breakpoint-small) {
    padding: 0 $content-offset;
  }

  @include until($breakpoint-small) {
    margin: rem(40) 0;
  }
}

.cmp-accordion__item {
  position: relative;
  @include grid-item;
  @include grid-item--12;

  @include from($breakpoint-small) {
    @include grid-item--10;
  }

  @include from($breakpoint-medium) {
    @include grid-item--8;
  }

  &:last-child {

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: $grid-half-gap;
      width: calc(100% - #{$grid-half-gap * 2});
      height: 1px;
      background-color: $accordion-line-color;
    }
  }
}

.cmp-accordion__header {

}

.cmp-accordion__button {
  position: relative;
  padding: rem(19) rem(110) rem(12) 0;
  background-color: transparent;
  border-color: $accordion-line-color;
  border-width: 1px 0 0 0;
  border-style: solid;
  outline: none;
  cursor: pointer;
  display: flex;

  &::before {
    content: '';
    width: 0;
    height: 1px;
    position: absolute;
    top: -1px;
    left: 0;
    transition: width 0.8s cubic-bezier(0.33, 1, 0.68, 1);
    background-color: $accordion-active-line-color;
  }

  @include until($breakpoint-small) {
    padding: rem(16) rem(60) rem(11) 0;
  }
}

.cmp-accordion__button--expanded {
  outline: none;

  &::before {
    width: 100%;
  }
}

.cmp-accordion__title {
  color: $accordion-button-color;
  font-weight: $font-weight-regular;
  font-size: $font-title-size-8;
  line-height: $font-title-height-8;
  position: relative;
  display: inline-block;

  &::before {
    content: '';
    width: 0;
    height: 1px;
    position: absolute;
    top: rem(-20);
    left: 0;
    transition: width 0.8s cubic-bezier(0.33, 1, 0.68, 1);
    background-color: $accordion-active-line-color;

    @include until($breakpoint-small) {
      top: rem(-17);
    }
  }

  .cmp-accordion__button:hover & {

    &::before {
      width: 100%;
    }
  }

  @include until($breakpoint-small) {
    font-size: $font-title-size-8-mobile;
    line-height: $font-title-height-8-mobile;
    min-height: rem(28);
  }
}

.cmp-accordion__icon {
  width: rem(22);
  height: rem(22);
  position: absolute;
  right: 0;
  top: rem(18);
  border: 1px solid $accordion-icon-border-color;
  background-color: $accordion-icon-background-color;
  border-radius: 50%;
  cursor: pointer;

  &::before, &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $accordion-icon-color;
    mask-repeat: no-repeat;
    mask-size: cover;
    transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  }

  &::before {
    @extend .icon-plus;
    transform: rotate(0);
  }

  &::after {
    @extend .icon-minus;
    transform: rotate(-90deg);
    opacity: 0;
  }

  &:hover, .cmp-accordion__button--expanded & {
    background-color: $accordion-active-icon-border-color;
    border-color: $accordion-active-icon-border-color;

    &::before, &::after {
      background-color: $accordion-active-icon-color;
    }
  }

  .cmp-accordion__button--expanded & {

    &::before {
      opacity: 0;
      transform: rotate(90deg);
    }

    &::after {
      opacity: 1;
      transform: rotate(0);
    }
  }

  @include until($breakpoint-small) {
    top: rem(17);
  }
}

.cmp-accordion__panel {

  .cmp-text {
    padding: rem(48) 0 rem(38);

    p, li {
      font-size: $font-size-2;
      line-height: $font-height-2;
    }

    p, blockquote, ul, ol {
      padding-left: 0;
      padding-right: 0;
      width: 62%;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include until($breakpoint-small) {
        width: 74%;
      }
    }

    @include until($breakpoint-small) {
      padding: rem(38) 0 rem(7);
    }
  }

  .cmp-download{
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.cmp-accordion__panel--expanded {

}

.cmp-accordion__panel--hidden {

}

.container--gray {

  .cmp-accordion__item:last-child::before {
    background-color: $accordion-container-gray-line-color;
  }

  .cmp-accordion__button {
    border-color: $accordion-container-gray-line-color;

    &::before {
      background-color: $accordion-container-gray-active-line-color;
    }
  }

  .cmp-accordion__title {
    color: $accordion-container-gray-button-color;

    &::before {
      background-color: $accordion-container-gray-active-line-color;
    }
  }

  .cmp-accordion__icon {
    background-color: $accordion-container-gray-icon-background-color;
    border-color: $accordion-container-gray-icon-border-color;

    &::before, &::after {
      background-color: $accordion-container-gray-icon-color;
    }
  }

  .cmp-accordion__icon:hover, .cmp-accordion__button--expanded .cmp-accordion__icon {
    background-color: $accordion-container-gray-active-icon-border-color;
    border-color: $accordion-container-gray-active-icon-border-color;

    &::before, &::after {
      background-color: $accordion-container-gray-active-icon-color;
    }
  }
}

.container--gray-secondary {

  .cmp-accordion__item:last-child::before {
    background-color: $accordion-container-gray-secondary-line-color;
  }

  .cmp-accordion__button {
    border-color: $accordion-container-gray-secondary-line-color;

    &::before {
      background-color: $accordion-container-gray-secondary-active-line-color;
    }
  }

  .cmp-accordion__title {
    color: $accordion-container-gray-secondary-button-color;

    &::before {
      background-color: $accordion-container-gray-secondary-active-line-color;
    }
  }

  .cmp-accordion__icon {
    background-color: $accordion-container-gray-secondary-icon-background-color;
    border-color: $accordion-container-gray-secondary-icon-border-color;

    &::before, &::after {
      background-color: $accordion-container-gray-secondary-icon-color;
    }
  }

  .cmp-accordion__icon:hover, .cmp-accordion__button--expanded .cmp-accordion__icon {
    background-color: $accordion-container-gray-secondary-active-icon-border-color;
    border-color: $accordion-container-gray-secondary-active-icon-border-color;

    &::before, &::after {
      background-color: $accordion-container-gray-secondary-active-icon-color;
    }
  }
}

.container--quaternary {

  .cmp-accordion__item:last-child::before {
    background-color: $accordion-container-quaternary-line-color;
  }

  .cmp-accordion__button {
    border-color: $accordion-container-quaternary-line-color;

    &::before {
      background-color: $accordion-container-quaternary-active-line-color;
    }
  }

  .cmp-accordion__title {
    color: $accordion-container-quaternary-button-color;

    &::before {
      background-color: $accordion-container-quaternary-active-line-color;
    }
  }

  .cmp-accordion__icon {
    background-color: $accordion-container-quaternary-icon-background-color;
    border-color: $accordion-container-quaternary-icon-border-color;

    &::before, &::after {
      background-color: $accordion-container-quaternary-icon-color;
    }
  }

  .cmp-accordion__icon:hover, .cmp-accordion__button--expanded .cmp-accordion__icon {
    background-color: $accordion-container-quaternary-active-icon-border-color;
    border-color: $accordion-container-quaternary-active-icon-border-color;

    &::before, &::after {
      background-color: $accordion-container-quaternary-active-icon-color;
    }
  }
}
