@use 'sass:math';

.v-filter-tags {
  display: flex;
  flex-wrap: wrap;
  margin: -#{$tag-margin-vertical-medium} -#{$tag-margin-horizontal-medium};
  padding: 0;

  @include from($breakpoint-small) {
    margin: 0 -#{$tag-margin};
  }
}

.v-filter-tags__item {
  position: relative;
  border: 1px solid $tag-background;
  border-radius: $tag-border-radius;
  margin: $tag-margin-vertical-medium $tag-margin-horizontal-medium;
  padding: $tag-padding-medium;
  height: $tag-height-medium;
  font-size: $tag-font-size-medium;
  line-height: $tag-font-height-medium;
  background-color: $tag-background;
  color: $tag-font-color;
  text-decoration: none;
  cursor: pointer;
  transition: margin 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 1px dashed $tag-outline;
    opacity: 0;
    border-radius: $tag-border-radius;
    transition: width 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  }

  &:focus {
    outline: none;
    color: $tag-font-color;
  }

  &:focus-visible {
    &::before {
      opacity: 1;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: $tag-disabled-background;
    color: $tag-disabled-font-color;
  }

  &.cmp-top-news__tag--show-all {
    &:disabled {
      opacity: 1;
      cursor: not-allowed;
      background-color: $tag-active-background;
      border-color: $tag-active-background;
      color: $tag-active-font-color;
      padding: 0.4375rem 1.5rem 0.5625rem 1.5rem;

      .v-filter-tags__item-icon {
        display: none;
      }

      @include from($breakpoint-small) {
        padding: 0.625rem 2.4375rem 0.75rem 2.4375rem;
      }
    }
  }

  @include from($breakpoint-small) {
    margin: $tag-margin;
    padding: $tag-padding;
    height: $tag-height;
    font-size: $tag-font-size;
    line-height: $tag-font-height;
  }
}

.v-filter-tags__item-icon {
  position: absolute;
  z-index: 1;
  top: -1px;
  right: rem(-1);
  width: $tag-height-medium;
  height: $tag-height-medium;
  border-radius: 50%;
  transition: opacity 0.3s cubic-bezier(0.33, 1, 0.68, 1);

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background-color: $tag-active-background;
    transform: scale(0);
    transition: transform 0.1s cubic-bezier(0.33, 1, 0.68, 1);
    border-radius: 50%;
  }

  @include from($breakpoint-small) {
    right: rem(-1);
    width: $tag-height;
    height: $tag-height;
  }
}

.v-filter-tags__item-icon-inner {
  position: absolute;
  top: calc(50% - #{rem(10)});
  left: calc(50% - #{rem(10)});
  width: rem(20);
  height: rem(20);
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $tag-font-color;
    mask-size: 100%;
    transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    transform: translateZ(0);
  }

  &::before {
    @extend .icon-plus;
  }

  &::after {
    @extend .icon-minus;
    opacity: 0;
  }

  @include from($breakpoint-small) {
    top: calc(50% - #{rem(11)});
    left: calc(50% - #{rem(11)});
    width: rem(22);
    height: rem(22);
  }
}

.v-filter-tags__item--active,
.v-filter-tags__item:not([disabled]):hover {
  color: $tag-font-color;

  .v-filter-tags__item-icon {
    border-color: $tag-active-background;
    opacity: 1;
    background-color: $white;
    border: 1px solid $tag-background;
  }

  .v-filter-tags__item-icon-inner {
    background-color: $tag-active-background;

    &:before,
    &:after {
      background-color: $tag-active-font-color;
    }
  }
}

.v-filter-tags__item:active {

  .v-filter-tags__item-icon-inner {
    transform: rotate(45deg);
  }

  .v-filter-tags__item-icon {

    &:before {
      transform: scale(1);
    }
  }
}

.v-filter-tags__item--active {
  background-color: $tag-active-background;
  border-color: $tag-active-background;
  color: $tag-active-font-color;

  .v-filter-tags__item-icon-inner {
    transform: rotate(45deg);

    &:before,
    &:after {
      background-color: $tag-active-font-color;
    }
  }

  &:not([disabled]):hover {
    color: $tag-active-font-color;

    .v-filter-tags__item-icon-inner {
      transform: rotate(0deg);

      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  &:focus {
    color: $tag-active-font-color;
  }

  &:disabled {
    background-color: $tag-active-background;
    border-color: $tag-active-background;
    color: $tag-active-font-color;
  }
}

.v-filter-tags__item--medium {
  margin: $tag-margin-vertical-medium $tag-margin-horizontal-medium;
  padding: $tag-padding-medium;
  height: $tag-height-medium;
  font-size: $tag-font-size-medium;
  line-height: $tag-font-height-medium;

  .v-filter-tags__item-icon {
    width: $tag-height-medium;
    height: $tag-height-medium;
  }

  .v-filter-tags__item-icon-inner {
    top: calc(50% - #{rem(10)});
    left: calc(50% - #{rem(10)});
    width: rem(20);
    height: rem(20);
  }
}

.v-filter-tags__item--search {
  background-color: $tag-search-background;
  border-color: $tag-search-border-color;
  color: $tag-search-font-color;

  .v-filter-tags__item-icon {

    &:before {
        background-color: $tag-search-active-background;
    }
  }

  .v-filter-tags__item-icon-inner {
    background-color: $tag-search-background;

    &::before,
    &::after {
      background-color: $tag-search-font-color;
    }
  }

  &.v-filter-tags__item--active,
  &.v-filter-tags__item:not([disabled]):hover {
    color: $tag-search-active-font-color;

    .v-filter-tags__item-icon-inner {
      background-color: $tag-search-active-background;

      &::before,
      &::after {
        background-color: $tag-search-active-font-color;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 1px dashed $tag-search-border-color;
    z-index: 1;
    pointer-events: none;
    border-radius: $tag-border-radius;
  }
}
