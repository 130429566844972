.teaser.teaser--topic {

  .columncontainer & {

    .cmp-teaser {
      margin-left: -6.72%;
      margin-right: -6.72%;

      @include until($breakpoint-small) {
        margin-left: -5.48%;
        margin-right: -5.48%;
      }
    }
  }

  .cmp-teaser, .cmp-teaser > .cmp-teaser__link {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    @include until($breakpoint-small) {
      flex-direction: column-reverse;
    }
  }

  .cmp-teaser {
    padding: rem(50) 0 rem(190);

    @include until($breakpoint-medium) {
      padding: rem(50) 0 rem(150);
    }

    @include until($breakpoint-small) {
      padding: rem(50) 0 0;
    }
  }

  .cmp-teaser > .cmp-teaser__link {
    text-decoration: none;
    color: $topic-teaser-white-text-color;
  }

  .cmp-teaser__image {
    position: relative;
    padding-bottom: 37.8%;
    width: 58.45%;

    & .cmp-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin-top: 0;
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include until($breakpoint-small) {
      width: 80%;
      left: 10%;
      padding-bottom: 51.68%;
    }
  }

  .cmp-teaser__content {
    width: 35.55%;
    padding: rem(80);
    background-color: $topic-teaser-white-background;
    position: relative;
    top: rem(130);
    left: rem(138);
    z-index: 1;

    @include until($breakpoint-large) {
      width: 40%;
      padding: rem(60);
    }

    @include until($breakpoint-medium) {
      padding: rem(40);
      width: 41%;
      top: rem(90);
      left: rem(80);
    }

    @include until($breakpoint-small) {
      width: 100%;
      padding: rem(40) 10%;
      top: 0;
      left: 0;
    }
  }

  .cmp-teaser__pretitle {
    font-size: $font-date;
    line-height: $font-date-height;
    font-weight: $font-weight-semi-bold;
    margin-top: 0;
    margin-bottom: rem(27);
    color: $topic-teaser-white-text-color;
    letter-spacing: 1px;
  }

  .cmp-teaser__title {
    font-size: $font-title-size-5;
    line-height: $font-title-height-5;
    font-weight: $font-weight-regular;
    margin-top: 0;
    margin-bottom: rem(42);
    color: $topic-teaser-white-text-color;
    overflow-wrap: break-word;
    hyphens: auto;

    @include until($breakpoint-small) {
      font-size: $font-title-size-5-mobile;
      line-height: $font-title-height-5-mobile;
      margin-bottom: rem(30);
    }
  }

  .cmp-teaser__title-link {
    text-decoration: none;
    color: $topic-teaser-white-text-color;
  }

  .cmp-teaser__description {
    font-size: $font-size-2;
    line-height: $font-height-2;
    font-weight: $font-weight-semi-bold;
    margin-top: 0;
    margin-bottom: 0;
    color: $topic-teaser-white-text-color;

    a:not(.cmp-teaser__action-link) {
      color: $topic-teaser-white-text-link-color;

      &:hover {
        color: $topic-teaser-white-text-link-hover-color;
      }
    }

    & p:last-child {
      margin-bottom: 0;
    }

    & p:first-child {
      margin-top: 0;
    }
  }

  .cmp-teaser__action-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(39);
    margin-bottom: rem(10);

    @include until($breakpoint-small) {
      margin-top: rem(22);
      margin-bottom: rem(15);
    }
  }

  .cmp-teaser__action-link {
    @extend .cmp-button;
    margin: 0 !important;

    &:not(:last-child) {
      margin-right: 1rem !important;

      @include until($breakpoint-small) {
        margin-right: 0.5rem !important;
      }
    }
  }

  &.topic-teaser--image-left {

    .cmp-teaser, .cmp-teaser > .cmp-teaser__link {
      // justify-content: flex-start;
      flex-direction: row-reverse;

      @include until($breakpoint-small) {
        flex-direction: column-reverse;
      }
    }

    .cmp-teaser__content {
      left: auto;
      right: rem(138);

      @include until($breakpoint-small) {
        right: 0;
      }
    }
  }

  &:not(.topic-teaser--image-left) {

    .cmp-image {

      .cmp-image__copyright {

        @include from($breakpoint-small) {
          left: auto;
          right: 0;
        }
      }
    }
  }

  &.teaser--style-primary {

    .cmp-teaser > .cmp-teaser__link,
    .cmp-teaser__content,
    .cmp-teaser__pretitle,
    .cmp-teaser__title,
    .cmp-teaser__title-link,
    .cmp-teaser__description {
      color: $topic-teaser-primary-text-color;

      a:not(.cmp-teaser__action-link) {
        color: $topic-teaser-primary-text-link-color;

        &:hover {
          color: $topic-teaser-primary-text-link-hover-color;
        }
      }
    }

    .cmp-teaser__content {
      background-color: $topic-teaser-primary-background;
    }
  }

  &.teaser--style-secondary {

    .cmp-teaser > .cmp-teaser__link,
    .cmp-teaser__content,
    .cmp-teaser__pretitle,
    .cmp-teaser__title,
    .cmp-teaser__title-link,
    .cmp-teaser__description {
      color: $topic-teaser-secondary-text-color;

      a:not(.cmp-teaser__action-link) {
        color: $topic-teaser-secondary-text-link-color;

        &:hover {
          color: $topic-teaser-secondary-text-link-hover-color;
        }
      }
    }

    .cmp-teaser__content {
      background-color: $topic-teaser-secondary-background;
    }
  }

  &.teaser--style-tertiary {

    .cmp-teaser > .cmp-teaser__link,
    .cmp-teaser__content,
    .cmp-teaser__pretitle,
    .cmp-teaser__title,
    .cmp-teaser__title-link,
    .cmp-teaser__description {
      color: $topic-teaser-tertiary-text-color;

      a:not(.cmp-teaser__action-link) {
        color: $topic-teaser-tertiary-text-link-color;

        &:hover {
          color: $topic-teaser-tertiary-text-link-hover-color;
        }
      }
    }

    .cmp-teaser__content {
      background-color: $topic-teaser-tertiary-background;
    }
  }

  &.teaser--style-quaternary {

    .cmp-teaser > .cmp-teaser__link,
    .cmp-teaser__content,
    .cmp-teaser__pretitle,
    .cmp-teaser__title,
    .cmp-teaser__title-link,
    .cmp-teaser__description {
      color: $topic-teaser-quaternary-text-color;

      a:not(.cmp-teaser__action-link) {
        color: $topic-teaser-quaternary-text-link-color;

        &:hover {
          color: $topic-teaser-quaternary-text-link-hover-color;
        }
      }
    }

    .cmp-teaser__content {
      background-color: $topic-teaser-quaternary-background;
    }
  }

  &.teaser--style-white,
  &:not(.teaser--style-primary),
  &:not(.teaser--style-tertiary),
  &:not(.teaser--style-quaternary),
  &:not(.teaser--style-secondary) {

    .cmp-teaser__action-link {
      @include add_button_style($topic-teaser-white-button-variant);
    }
  }

  &.teaser--style-primary {

    .cmp-teaser__action-link {
      @include add_button_style($topic-teaser-primary-button-variant);
    }
  }

  &.teaser--style-tertiary {

    .cmp-teaser__action-link {
      @include add_button_style($topic-teaser-tertiary-button-variant);
    }
  }

  &.teaser--style-quaternary {

    .cmp-teaser__action-link {
      @include add_button_style($topic-teaser-quaternary-button-variant);
    }
  }

  &.teaser--style-secondary {

    .cmp-teaser__action-link {
      @include add_button_style($topic-teaser-secondary-button-variant);
    }
  }
}
