.teaser.teaser--cta {

  &:not(.topic-teaser--variant-2) {

    .cmp-teaser {
      position: relative;

      @include until($breakpoint-small) {
        padding-bottom: 0;
      }
    }

    .cmp-teaser__image {
      position: relative;
      padding-bottom: 34.1%;
      left: 0;
      bottom: 0;
      width: 100%;

      & .cmp-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin-top: 0;

        @include until($breakpoint-small) {
          position: relative;
          height: auto;
        }
      }

      & img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include until($breakpoint-small) {
          height: auto;
          object-fit: fill;
        }
      }

      @include until($breakpoint-small) {
        position: relative;
        height: auto;
        padding-bottom: 0;
      }
    }

    .cmp-teaser__content {
      width: 100%;
      padding-left: 6%;
      padding-right: 6%;
      background-color: $cta-teaser-background;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: auto;
      align-items: start;
      margin-bottom: rem(55);
      padding-top: 15.7%;

      @include until($breakpoint-small) {
        padding-left: 5%;
        padding-right: 5%;
        height: auto;
        position: relative;
        padding-top: rem(28);
        display: block;
        transform: none;
        margin-bottom: 0;
      }
    }

    .cmp-teaser__pretitle {
      display: none;
    }

    .cmp-teaser__title {
      padding-right: rem(14);
      color: $cta-teaser-title-color;
      font-size: $font-title-size-4;
      line-height: $font-title-height-4;
      font-weight: $font-weight-regular;
      margin-top: 0;
      margin-bottom: 0;
      grid-column: 1;
      grid-row: 1 / span 2;
      hyphens: auto;
      overflow-wrap: break-word;

      @include until($breakpoint-small) {
        font-size: $font-title-size-5-mobile;
        line-height: $font-title-height-5-mobile;
        width: 83%;
        padding-right: 0;
        margin-bottom: rem(40);
      }
    }

    .cmp-teaser__title-link {
      text-decoration: none;
      color: $cta-teaser-title-color;
    }

    .cmp-teaser__description {
      padding-left: rem(14);
      display: flex;
      justify-content: space-between;
      color: $cta-teaser-text-color;
      font-size: $font-size-2;
      line-height: $font-height-2;
      font-weight: $font-weight-semi-bold;
      margin-top: 0;
      margin-bottom: 0;
      grid-column: 2;
      grid-row: 1;

      > * {
        width: calc(37.32% - #{rem(14)});
        display: none;
        margin-top: 0;
        margin-bottom: 0;

        @include until($breakpoint-small) {
          width: 100%;
          margin-bottom: rem(18);
        }
      }

      > p {
        font-size: $font-size-2;
        line-height: $font-height-2;
        font-weight: $font-weight-semi-bold;
      }

      > p:nth-child(1), > p:nth-child(2) {
        display: block;
      }

      @include until($breakpoint-small) {
        flex-wrap: wrap;
        width: 83%;
        padding-left: 0;
      }
    }

    .cmp-teaser__action-container {
      padding-left: rem(14);
      margin-top: rem(55);
      display: flex;
      grid-column: 2;
      grid-row: 2;

      @include until($breakpoint-small) {
        margin-top: 0;
        margin-bottom: rem(27);
        padding-left: 0;
      }
    }

    .cmp-teaser__action-link {
      @extend .cmp-button;
      @include add_button_style($cta-teaser-button-variant);
      margin: 0 !important;

      .cmp-link__screen-reader-only {
        position: absolute;
        left: -9999px;
      }

      &:not(:last-child) {
        margin-right: rem(20) !important;
      }
    }
  }

  &.topic-teaser--variant-2 {

    .cmp-teaser {
      position: relative;

      @include until($breakpoint-small) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .cmp-teaser__image {
      width: 100%;
      overflow: hidden;

      & .cmp-image {
        margin-top: 0;
        width: 100%;
      }

      & img {
        width: 100%;
        height: auto;
        overflow: hidden;

        @media screen and (max-width: 1350px) {
          width: 120%;
          position: relative;
          left: -10%;
        }

        @include until($breakpoint-large) {
          width: 130%;
          left: -15%;
        }

        @include until($breakpoint-medium) {
          width: 140%;
          left: -20%;
        }

        @media screen and (max-width: 850px) {
          width: 180%;
          position: relative;
          left: -40%;
        }

        @include until($breakpoint-small) {
          width: 100%;
          left: 0;
        }

        @media screen and (max-width: 550px) {
          width: 120%;
          position: relative;
          left: -10%;
        }
      }
    }

    .cmp-teaser__content {
      position: absolute;
      left: 6%;
      top: 50%;
      width: 28.06%;
      transform: translateY(-50%);
      z-index: 1;
      background-color: $cta-teaser-variant-2-background;
      padding: rem(38) rem(38) rem(47);

      @media screen and (max-width: 1350px) {
        width: 35%;
      }

      @include until($breakpoint-large) {
        width: 42%;
      }

      @include until($breakpoint-medium) {
        width: 56%;
      }

      @include until($breakpoint-small) {
        position: relative;
        width: 100%;
        padding: rem(28) 5% rem(25);
        transform: none;
        top: 0;
        left: 0;
      }
    }

    .cmp-teaser__title {
      color: $cta-teaser-variant-2-title-color;
      font-size: $font-title-size-5;
      line-height: $font-title-height-5;
      font-weight: $font-weight-regular;
      margin-top: 0;
      margin-bottom: rem(40);
      padding-right: 10%;

      @include until($breakpoint-small) {
        font-size: $font-title-size-5-mobile;
        line-height: $font-title-height-5-mobile;
        margin-bottom: rem(20);
      }
    }

    .cmp-teaser__title-link {
      text-decoration: none;
      color: $cta-teaser-variant-2-title-color;
    }

    .cmp-teaser__description {
      color: $cta-teaser-variant-2-text-color;
      font-size: $font-size-2;
      line-height: $font-height-2;
      font-weight: $font-weight-regular;
      margin-top: 0;
      margin-bottom: rem(40);
      padding-right: 10%;

      > p {
        font-size: $font-size-2;
        line-height: $font-height-2;
        font-weight: $font-weight-semi-bold;
      }

      > p:nth-child(1), > p:nth-child(2) {
        display: block;
      }
    }

    .cmp-teaser__pretitle {
      display: none;
    }

    .cmp-teaser__action-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @include until($breakpoint-small) {

      }
    }

    .cmp-teaser__action-link {
      @extend .cmp-button;
      margin: 0 0 rem(20) 0 !important;

      .cmp-link__screen-reader-only {
        position: absolute;
        left: -9999px;
      }

      &:not(:first-child) {
        @include add_button_style($cta-teaser-variant-2-button-variant);
      }

      &:first-child:not(:only-child) {
        @include add_button_style($cta-teaser-variant-2-button-secondary-variant);
      }

      &:not(:last-child) {
        margin-right: rem(20) !important;
      }
    }
  }
}
