.teaser.teaser--testimonial {
  container: testimonial-teaser / inline-size;

  .cmp-teaser {
    display: flex;
    flex-direction: column-reverse;
    background-color: $testimonial-teaser-background;
    justify-content: space-between;
    flex-wrap: wrap;

    > .cmp-teaser__link {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;

      @container testimonial-teaser (inline-size > 550px) {
        flex-direction: row-reverse;
      }
    }

    @container testimonial-teaser (inline-size > 550px) {
      flex-direction: row-reverse;
    }
  }

  .cmp-teaser__content {
    position: relative;
    width: 100%;
    padding-bottom: rem(210);

    @container testimonial-teaser (inline-size > 550px) {
      width: 44.65%;
      padding-bottom: 0;
    }
  }

  .cmp-teaser__pretitle {
    color: $testimonial-teaser-pretitle-color;
    font-size: $font-title-size-6-mobile;
    line-height: $font-title-height-6-mobile;
    max-height: $font-title-size-6-mobile * $font-title-height-6-mobile;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    bottom: rem(126);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;

    @container testimonial-teaser (inline-size > 700px) {
      font-size: $font-title-size-7;
      line-height: $font-title-height-7;
      max-height: $font-title-size-7 * $font-title-height-7;
      bottom: rem(199);
    }
  }

  .cmp-teaser__title {
    color: $testimonial-teaser-title-color;
    font-weight: $font-weight-extra-bold;
    position: absolute;
    left: 0;
    bottom: rem(156);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: $font-title-size-6-mobile;
    line-height: $font-title-height-6-mobile;
    max-height: $font-title-size-6-mobile * $font-title-height-6-mobile;

    a {
      color: $testimonial-teaser-title-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    @container testimonial-teaser (inline-size > 700px) {
      font-size: $font-title-size-7;
      line-height: $font-title-height-7;
      max-height: $font-title-size-7 * $font-title-height-7;
      bottom: rem(232);
    }
  }

  .cmp-teaser__description {
    color: $testimonial-teaser-text-color;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: $font-size-1-b-mobile;
    line-height: $font-height-1-b-mobile;
    max-height: $font-size-1-b-mobile * $font-height-1-b-mobile * 6;
    height: $font-size-1-b-mobile * $font-height-1-b-mobile * 6;

    p, a {
      color: $testimonial-teaser-text-color;
      font-size: $font-size-1-b-mobile;
      line-height: $font-height-1-b-mobile;

      @container testimonial-teaser (inline-size > 700px) {
        font-size: $font-size-1;
        line-height: $font-height-1;
      }
    }

    p {

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @container testimonial-teaser (inline-size > 700px) {
      font-size: $font-size-1;
      line-height: $font-height-1;
      max-height: $font-size-1 * $font-height-1 * 6;
      height: $font-size-1 * $font-height-1 * 6;
    }
  }

  .cmp-teaser__image {
    position: relative;
    padding-bottom: 100%;
    width: 100%;

    &::before {
      content: '';
      background-color: $testimonial-teaser-icon-color;
      width: rem(39);
      height: rem(30);
      mask-size: 100%;
      mask-repeat: no-repeat;
      mask-position: center;
      @extend .icon-quotes;
      position: absolute;
      left: rem(20);
      bottom: rem(26);
      z-index: 1;

      @container testimonial-teaser (inline-size > 700px) {
        left: rem(30);
        bottom: rem(39);
        width: rem(58);
        height: rem(44);
      }
    }

    & .cmp-image {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @container testimonial-teaser (inline-size > 550px) {
      padding-bottom: 47.86%;
      width: 47.86%;
    }
  }

  .cmp-teaser__action-container {
    display: none;
  }
}
