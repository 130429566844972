.cmp-top-article {
  position: relative;
  margin-top: rem(84);
  margin-bottom: rem(84);
  padding-top: rem(50);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 6%;
    width: 88%;
    height: 1px;
    background-color: $top-news-topline-sub-color;
  }

  @include until($breakpoint-small) {
    padding-top: rem(20);
    margin-top: rem(60);
    margin-bottom: rem(60);
  }
}

.cmp-top-article__content-wrapper {
  padding-left: 6%;
  padding-right: 6%;
}

.cmp-top-article__header {
  margin-bottom: rem(33);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @include until($breakpoint-small) {
    flex-wrap: wrap;
    margin-bottom: rem(40);
  }
}

.cmp-top-article__main-title {
  margin-top: 0;
  margin-bottom: 0;
  color: $top-news-title-color;
  padding-right: rem(30);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: rem(-51);
    left: 0;
    width: calc(100% - #{rem(30)});
    height: 2px;
    background-color: $top-news-topline-color;

    @include until($breakpoint-small) {
      height: 1px;
      top: rem(-20);
    }
  }
}

.cmp-top-article__main-tag {
  position: absolute;
  top: rem(-71);
  right: 0;
  padding-left: rem(45);
  height: rem(44);
  display: flex;
  align-items: center;
  background-color: $top-article-main-tag-background;
  font-size: $font-size-1;
  line-height: $font-height-1;
  font-weight: $font-weight-semi-bold;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: rem(29);
    width: 1px;
    height: 100%;
    background-color: $top-news-vertical-line-color;

    @include until($breakpoint-small) {
      left: rem(20);
    }
  }

  @include until($breakpoint-small) {
    font-size: $font-size-1-mobile;
    line-height: $font-height-1-mobile;
    top: rem(-40);
    padding-left: rem(35);
    height: rem(38);
  }
}

.cmp-top-article__article-content-wrapper {
  padding-left: 6%;
  padding-right: 6%;
}

.cmp-top-article__article {
  width: 100%;
  margin-bottom: rem(70);
  position: relative;

  @include until($breakpoint-small) {
    margin-bottom: rem(46);
  }
}

.cmp-top-article__article-image-wrap {
  display: flex;
  position: relative;
  padding-bottom: 33%;
  width: 100%;

  > a {
    width: 100%;

    > div {
      width: 100%;
    }
  }

  .cmp-image {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @include until($breakpoint-medium) {
    padding-bottom: 50%;
  }

  @include until($breakpoint-small) {
    padding-bottom: 70%;
  }
}

.cmp-top-article__article-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: rem(71);

  @include until($breakpoint-small) {
    flex-wrap: wrap;
    padding-bottom: rem(55);
  }
}

.cmp-top-article__article-title {
  color: $top-news-article-title-color;
  font-weight: $font-weight-semi-bold;
  margin-top: 0;
  margin-bottom: rem(20);
  width: 39%;
  padding-top: rem(37);

  a {
    text-decoration: none;
    color: $top-news-article-title-color;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  @include until($breakpoint-medium) {
    width: 45%;
  }

  @include until($breakpoint-small) {
    width: 100%;
    font-size: $font-title-size-6-mobile;
    line-height: $font-title-height-6-mobile;
    padding-top: 0;
    margin-bottom: rem(10);
  }
}

.cmp-top-article__article-text-wrapper {
  width: 31.9%;

  @include until($breakpoint-medium) {
    width: 50%;
  }

  @include until($breakpoint-small) {
    width: 100%;
  }
}

.cmp-top-article__article-text {
  color: $top-news-article-text-color;
  font-weight: $font-weight-regular;
  font-size: $font-size-2;
  line-height: $font-height-2;

  p {
    font-size: $font-size-2;
    line-height: $font-height-2;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @include until($breakpoint-small) {
    font-size: $font-size-1-mobile;
    line-height: $font-height-1-mobile;
    width: 100%;
  }
}

.cmp-top-article__article-icon-wrapper {
  position: absolute;
  left: 0;
  bottom: rem(-70);
  z-index: 1;

  @include until($breakpoint-small) {
    bottom: rem(-42);
  }
}

.cmp-top-article__article-icon {
  background-color: $top-article-icon-background;
  width: rem(140);
  height: rem(140);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }

  @include until($breakpoint-small) {
    width: rem(80);
    height: rem(80);
  }
}

.cmp-top-article__article-date {
  font-size: $font-date;
  line-height: $font-date-height;
  letter-spacing: 1px;
  font-weight: $font-weight-semi-bold;
  margin-bottom: rem(18);
}
